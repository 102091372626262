window.addEventListener("load", (event) => {
  const changePrice = document.querySelector(".switch__btn");
  const card = document.querySelectorAll(".card__price");
  const paiement = document.querySelectorAll(".card__paiement");

  changePrice.addEventListener("click", () => {
    changePrice.classList.toggle("switch__btn--active");

    if (changePrice.classList.contains("switch__btn--active")) {
      card.forEach((item) => {
        item.innerHTML = Math.round(item.innerHTML / 3.81);
      });
      paiement.forEach((item) => {
        item.innerHTML = "/ 12x";
      });
    } else {
      card.forEach((item) => {
        item.innerHTML = Math.round(item.innerHTML * 3.81);
      });
      paiement.forEach((item) => {
        item.innerHTML = "/ 3x";
      });
    }
  });
});
