window.addEventListener("load", (event) => {
  const btnTop = document.querySelector("#btn--top");

  document.addEventListener("scroll", () => {
    let y = window.pageYOffset;

    if (y > 500) {
      btnTop.classList.add("btn--top--active");
    } else {
      btnTop.classList.remove("btn--top--active");
    }
  });
});
