window.addEventListener("load", (event) => {
  // bouton menu
  const menu_btn = document.querySelector("#menu__btn");
  // menu
  const headerMenu = document.querySelector(".header__menu");
  // body
  const menu_body = document.querySelector("body");
  // menu main
  const menuItem = document.querySelectorAll(".menu__item");

  menuItem.forEach((item) => {
    item.addEventListener("click", () => {
      headerMenu.classList.toggle("header__menu--active");
      menu_btn.classList.toggle("menu__btn--active");
      menu_body.classList.toggle("menu__body--active");
    });
  });

  menu_btn.addEventListener("click", () => {
    headerMenu.classList.toggle("header__menu--active");
    menu_btn.classList.toggle("menu__btn--active");
    menu_body.classList.toggle("menu__body--active");
  });

  let startPosition,
    currentPosition = 0;
  const header = document.querySelector(".header");

  document.addEventListener("scroll", () => {
    let currentOffset = window.pageYOffset;
    let topPage = 0;

    currentPosition = currentOffset;

    if (startPosition < currentPosition && currentOffset > topPage) {
      header.classList.add("header--hidden");
    } else if (startPosition > currentPosition && !(currentOffset <= topPage)) {
      header.classList.add("header--fixed");
      header.classList.remove("header--hidden");
    } else if (currentOffset <= topPage) {
      header.classList.remove("header--fixed");
    }

    startPosition = currentPosition;
  });
});
