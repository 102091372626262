import anime from "animejs/lib/anime.es";
window.addEventListener("load", (event) => {
  anime({
    targets: "#gear",
    rotate: "360",
    loop: true,
    easing: "linear",
    duration: 2500,
  });

  anime({
    targets: ".love",
    scale: [1, 2, 1, 1.4],
    duration: 300,
    delay: 2200,
    loop: true,
    ease: "easeOutBack",
  });
  var textWrapper = document.querySelector(".word");
  textWrapper.innerHTML = textWrapper.textContent.replace(
    /\S/g,
    "<span class='letter'>$&</span>"
  );

  anime.timeline({ loop: true }).add({
    targets: ".letter",
    rotateY: [-90, 0],
    duration: 2500,
    delay: (el, i) => 90 * i,
  });

  let observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          entry.target.classList.add("fade--in");
        }
      });
    },
    {
      threshold: 0,
      rootMargin: "-200px 0px 0px 0px",
    }
  );

  const fade = document.querySelectorAll(".fade");

  fade.forEach((card) => {
    observer.observe(card);
  });
});
