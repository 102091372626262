import "../sass/main.scss";
import "./navigation.js";
import "./contact-form.js";
import "./price.js";
import "./modal.js";
import "./animation.js";
import "./buttonTop";

jQuery(document).ready(function ($) {
  $(".menu__main a").on("click", function () {
    $(".header__menu").removeClass("header__menu--active");
    $("#menu__btn").removeClass("menu__btn--active");
    $("body").removeClass("menu__body--active");
    $(".header").removeClass("header-active");
  });

  $("#menu__btn").on("click", function () {
    $(".header").toggleClass("header-active");
  });
});
