window.addEventListener("load", (event) => {
  const open_contact = document.querySelectorAll(".btn__contact");
  const open_form = document.querySelector(".contact-form");
  const love = document.querySelector(".btn__love");
  const close_form = document.querySelector(".contact-form__close");
  const body = document.querySelector("body");

  love.addEventListener("click", () => {
    open_form.classList.toggle("contact-form--active");
    body.classList.add("contact-form--open");
  });

  document.addEventListener("mouseup", (e) => {
    if (!e.target.closest(".contact-form")) {
      open_form.classList.remove("contact-form--active");
      body.classList.remove("contact-form--open");
    }
  });

  open_contact.forEach((item) => {
    item.addEventListener("click", () => {
      open_form.classList.toggle("contact-form--active");
      body.classList.add("contact-form--open");
    });
  });

  close_form.addEventListener("click", () => {
    open_form.classList.remove("contact-form--active");
    body.classList.remove("contact-form--open");
  });

  // var wpcf7Elm = document.querySelector(".wpcf7");

  // wpcf7Elm.addEventListener(
  //   "wpcf7mailsent",
  //   function (event) {
  //     if (open_form) {
  //       open_form.innerHTML =
  //         "<div class='contact-form--success'><p>Merci pour votre message, nous reviendrons vers vous au plus vite</p></div><button class='contact-form__close'><div><span class='close__bar close__bar--1'></span><span class='close__bar close__bar--2'></span></div><span class='close__title'>Fermer</span></button>";
  //       document
  //         .querySelector(".contact-form__close")
  //         .addEventListener("click", () => {
  //           open_form.classList.remove("contact-form--active");
  //           body.classList.remove("contact-form--open");
  //         });
  //     }
  //   },
  //   false
  // );
});
