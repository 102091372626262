window.addEventListener("load", (event) => {
  const modalWrapper = document.querySelector(".modal");
  const modalBtn = document.querySelector(".btn__modal");
  const modalBody = document.querySelector("body");
  const modalClose = document.querySelector(".modal__close");

  if (modalBtn) {
    modalBtn.addEventListener("click", () => {
      modalBody.classList.add("body__modal--active");
      modalWrapper.classList.add("modal--active");
    });

    modalClose.addEventListener("click", () => {
      modalBody.classList.remove("body__modal--active");
      modalWrapper.classList.remove("modal--active");
    });

    document.addEventListener("mouseup", (e) => {
      if (!e.target.closest(".modal__wrapper")) {
        modalBody.classList.remove("body__modal--active");
        modalWrapper.classList.remove("modal--active");
      }
    });
  }
});
